/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang="de", meta, title, image, article, schemaLD }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const metaTags = [
    {
      name: `title`,
      content: title,
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: article?`article`:`website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },/*
    {
      name: `twitter:creator`,
      content: site.siteMetadata.social.twitter,
    },*/
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(
    image
      ? [
          {
            property: "og:image",
            content: image,
          },
          {
            property: "twitter:image",
            content: image,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
        ]
      : [
          {
            name: "twitter:card",
            content: "summary",
          },
        ]
  )
  .concat(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={metaTags}
    >
      <html lang={lang} />
      <title>{title}</title>
      {schemaLD && (<script type="application/ld+json">{JSON.stringify(schemaLD)}</script>)}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: `Elektronik ESP32, Arduino Bastelprojekte mit Anleitungen zum nachbauen.`,
  image: null,
  article: false,
  structuredDataLD: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  article: PropTypes.bool,
  schemaLD: PropTypes.object,
}

export default SEO
